import { Controller } from "@hotwired/stimulus";
import MicroModal from 'micromodal';

// Connects to data-controller="sites"
export default class extends Controller {
  initialize() {
    MicroModal.init();

    if ($('.show-migration-status').length) {
      let site_id = $('.show-migration-status').data('targetId');
      $.get('/api/v1/migrations/site_status/' + site_id );
    }

    $('.page_filter_region').on('click', function(){
      let $clicked_fld = $(this);
      let total_inputs = 0;
      let total_checked = 0;

      $('.page_filter_region').each(function() {
        let $each_fld = $(this);
        total_inputs += 1;

        //Handle the All Click
        if( $clicked_fld.val() == 'all' ) {
          $each_fld.prop('checked', $clicked_fld.is(':checked'));
        }

        if($each_fld.is(':checked') && $each_fld.val() != 'all'){
          total_checked += 1;
        }
      });

      //Check the All box if the rest were also checked
      $('.page_filter_region[value="all"]').prop('checked', (total_checked == (total_inputs-1)));
    });
  }

  filter(event) {
    const goLive = document.querySelector('input[name="go_live"]:checked').value;
    const projectCategory = document.querySelector('input[name="project_category"]:checked').value;

    let regions = '';
    let total_inputs = 0;
    let total_checked = 0;
    $('.page_filter_region').each(function(){
      let $fld = $(this);
      total_inputs += 1;

      if( $fld.is(':checked') && $fld.val() != 'all' ) {
        if(regions != '') { regions += '-'; }
        regions += $fld.val();
        total_checked += 1;
      }
    });

    if( regions == '' || (total_checked == (total_inputs-1)) ) {
      regions = 'all';
    }

    window.location = `/sites/${goLive}/${regions}/${projectCategory}`;
  }
}
