import { Controller } from "@hotwired/stimulus"
import {getMetaValue} from "@rails/activestorage/src/helpers";

const RESET_TIMEOUT_MILLIS = 10000;
const CONFIRMATION_MESSAGE = "<p class='btn'>Are you sure?</p> <button class='btn btn-warning' , style ='font-size: small'>Yes</button>";

// Connects to data-controller="site-codes"
export default class extends Controller {
  connect() {
      this.delete = false;
  }
static targets = ["active", "link"]
  handleCustomerChange(event) {
    $.get(`/site_codes/regions?customer_id=${event.target.value}`, function (data) {
      $('#warehouse_site_region_id')
          .find('option')
          .remove();

      $('#warehouse_site_region_id').append(`<option value=""></option>`);

      data.forEach(function(region) {
        $('#warehouse_site_region_id').append(`<option value="${region.id}">${region.name}</option>`);
      });
    });
  }

  onChange(event) {
      fetch(`/site_codes/${event.target.id}/inline_update`,{
          method: 'PATCH',
          dataType: 'script',
          credentials: "include",
          headers:{
              "X-CSRF-Token" : getMetaValue("csrf-token")
          },
      }).then(function (response){
          Array.from(document.querySelectorAll(`.activity-link-${event.target.id}`)).forEach((icon) => {
              if(icon.classList.contains('d-none'))
                icon.classList.remove('d-none');
          });
      })
  }

    onDelete(event) {
        if (this.delete) {
            fetch(`/site_codes/${event.currentTarget.id}/delete`,{
                method: 'delete',
                dataType: 'script',
                credentials: "include",
                headers:{
                    "X-CSRF-Token" : getMetaValue("csrf-token")
                },
            }).then(function (response){
            });

            this.element.style = 'display: none;';
            this.linkTarget.addEventListener('error', this.handleError.bind(this));
            this.linkTarget.addEventListener('success', this.handleSuccess.bind(this));
        } else {
            this.oldMessage = this.linkTarget.innerHTML;
            this.linkTarget.innerHTML = CONFIRMATION_MESSAGE;
            this.delete = true;
            this.timeout = setTimeout(() => {
                this.resetState();
            }, RESET_TIMEOUT_MILLIS);
            event.preventDefault();
            return false;
        }

    }

    handleSuccess(event) {
        clearTimeout(this.timeout);
        this.element.parentNode.removeChild(this.element);
    }

    handleError(event) {
        clearTimeout(this.timeout);
        this.resetState();
        this.element.style = '';
    }

    resetState() {
        if (this.delete) {
            this.linkTarget.removeEventListener('success', this.handleSuccess.bind(this))
            this.linkTarget.removeEventListener('error', this.handleError.bind(this))
            this.linkTarget.innerHTML = this.oldMessage;
            this.delete = false;
        }
    }
}
