import { Controller } from "@hotwired/stimulus";
import MicroModal from 'micromodal';

// Connects to data-controller="sites"
export default class extends Controller {
  initialize() {
    MicroModal.init();

    $('.page_filter_region').on('click', function () {
      let $clicked_fld = $(this);
      let total_inputs = 0;
      let total_checked = 0;

      $('.page_filter_region').each(function () {
        let $each_fld = $(this);
        total_inputs += 1;

        //Handle the All Click
        if ($clicked_fld.val() == 'all') {
          $each_fld.prop('checked', $clicked_fld.is(':checked'));
        }

        if ($each_fld.is(':checked') && $each_fld.val() != 'all') {
          total_checked += 1;
        }
      });

      //Check the All box if the rest were also checked
      $('.page_filter_region[value="all"]').prop('checked', (total_checked == (total_inputs - 1)));
    });
  }

  filter_sites(event) {
    this.filter(event, 'sites');
  }

  filter_orders(event) {
    this.filter(event, 'orders');
  }

  filter_inventory(event) {
    this.filter(event, 'inventory');
  }

  filter(event, type) {
    let glObj = document.querySelector('input[name="go_live"]');
    let prObj = document.querySelector('input[name="project_category"]');

    const goLive = glObj && glObj.checked ? glObj.value : 'all';
    const projectCategory = prObj && prObj.checked ? prObj.value : 'all';

    let regions = '';
    let total_inputs = 0;
    let total_checked = 0;
    $('.page_filter_region').each(function () {
      let $fld = $(this);
      total_inputs += 1;

      if ($fld.is(':checked') && $fld.val() != 'all') {
        if (regions != '') {
          regions += '-';
        }
        regions += $fld.val();
        total_checked += 1;
      }
    });

    if (regions == '' || (total_checked == (total_inputs - 1))) {
      regions = 'all';
    }

    window.location = this.filter_url(type, goLive, regions, projectCategory);
  }

  filter_url(type, go_live, regions, category) {
    if (type == 'sites') {
      return `/sites/${go_live}/${regions}/${category}`;
    } else {
      return `/${type}/${regions}`;
    }
  }
}
