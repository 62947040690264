import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {}

  disableSubmit() {
    this.element.querySelector("input[type=submit]").disabled = true;
  }
}
