import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="grid--number-filter"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTarget.querySelector('option').setAttribute('disabled', 'true');
  }

  onChange(e) {
    let target = this.inputTarget;
    let input = target.parentElement.querySelector('input');
    let val = target.value;

    if (val === 'all') {
      val = ''
    }
    input.value = val;

    //add in the class showing a filter has been set
    if (val) {
      target.classList.add('filter-applied');
    } else {
      target.classList.remove('filter-applied');
    }

    apply_filter_to_datatable( input.dataset.filterBy, val );
  }
}
