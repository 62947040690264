import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="boxinfo"
export default class extends Controller {
  initialize() {
    //console.log('boxinfo initialize');
  }
  list(event) {
    let $caret_icon = $(event.target).find('.icon');
    const { id } = event.target.dataset;
    // Clear existing packing_lists contents
    Array.from(document.querySelectorAll(`.child-table-placeholder`)).forEach((tr) => {
      tr.style.display = 'none';
    });
    Array.from(document.querySelectorAll(`.icon-caret-down`)).forEach((icon) => {
      icon.classList.add('icon-caret-right');
      icon.classList.remove('icon-caret-down');
    });
    if (event.target.dataset.expanded === 'true') {
      // Skip
      event.target.dataset.expanded = 'false';
      $caret_icon.addClass('icon-caret-right').removeClass('icon-caret-down');
    } else {
      const boxinfoPlaceholder = document.querySelector(`#boxinfo-placeholder-${id}`);
      if (boxinfoPlaceholder !== null) {
        boxinfoPlaceholder.textContent = 'loading, please wait!'
        boxinfoPlaceholder.style = "text-align: center";

        boxinfoPlaceholder.style.display = 'table-row';

      }
      this.boxinfoData(event.target, id, boxinfoPlaceholder);
      event.target.dataset.expanded = 'true';
      $caret_icon.removeClass('icon-caret-right').addClass('icon-caret-down');
    }
  }

  boxinfoData(target, id, boxinfoPlaceholder) {

    $.get($(target).data('url')).then(function success(result){

    }, function error(err) {
      boxinfoPlaceholder.textContent = ''
    })
  }
}
