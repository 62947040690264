import {Controller} from "@hotwired/stimulus"
import moment from "moment";

// Connects to data-controller="insights--gpos"
export default class extends Controller {
  connect() {}

  selectEditionForDelete(e) {
    document.getElementById("enableDeleteButton").value = "";
    document.getElementById("deleteEditionId").value = e.target.closest('a').dataset.editionId;
  }

  enableDeleteButton(e) {
    document.getElementById("deleteReportButton").disabled = e.target.value.toLowerCase() !== "deletereport";
  }

  deleteReport(e) {
    const editionId = document.getElementById("deleteEditionId").value;
    if (editionId) {
      fetch(`/insights/gpos/${editionId}`, {method: 'DELETE'})
        .then((response) => response.text())
        .then(data => {
          document.querySelector(`#edition_row_${editionId}`)?.remove();
          const modal = bootstrap.Modal.getInstance('#delete-confirmation');
          modal.hide();
        });
    } else {
      alert("No report selected");
    }
  }

  validate(e) {
    document.querySelector('button#generateReport')?.classList.add('disabled');

    const startDate = document.querySelector('#insights_gpo_start_report_date')?.value;
    const endDate = document.querySelector('#insights_gpo_end_report_date')?.value;
    const parsedStartDate = moment(startDate);
    const parsedEndDate = moment(endDate);

    if (moment(startDate).isBefore(endDate)) {
      document.querySelector('button#generateReport')?.classList.remove('disabled');
    }
  }
}
