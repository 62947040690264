// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
var moment = require('moment')

import $ from 'jquery'
global.$ = jQuery

Rails.start()
ActiveStorage.start()

import "../stylesheets/application"
import "daterangepicker"

document.addEventListener("turbo:load", function(event) {
  window.initializeTooltips = function() {
    console.log("turbo:load");

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    });
  }
  window.initializeTooltips();

  jQuery.ajax({
    url: "https://denaliadvancedintegration.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-dtzt95/b/6/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=c262c76c",
    type: "get",
    cache: true,
    dataType: "script"
  });

  $(".filter-container input").on('keypress', function (e) {
    console.log(e);
    if (e.which === 13) {
      e.preventDefault();
      apply_filter_to_datatable($(this).data('filter-by'), $(this).val());
    }
  });

  $("#company_code").on('change', function (e) {
    let baseUrl = $(this).data('base-url');
    console.log(baseUrl);
    window.location = `${baseUrl}/${$(this).val()}`;
  });

  window.initiateDateRange = function() {
    $('input.date-range').each(function(){
      let $fld = $(this);

      $fld.on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        const e = jQuery.Event("keypress");
        e.which = 13; // # Some key code value
        $(this).trigger(e);
      });

      $fld.on('cancel.daterangepicker', function (ev, picker) {
        $(this).val('');
        const e = jQuery.Event("keypress");
        e.which = 13; // # Some key code value
        $(this).trigger(e);
      });

      let options = { autoUpdateInput: false }
      if( $fld.data('filter-min') ){options["minDate"] = `${$fld.data('filter-min')} 00:00`;  }
      if( $fld.data('filter-max') ){options["maxDate"] = `${$fld.data('filter-max')} 23:59`;  }

      $fld.daterangepicker(options);
    });

    $('input.date-select').each(function(){
      let $fld = $(this);
      let options = {
        singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: false,
        autoApply: true,
      }
      $fld.daterangepicker(options);

      $('input.date-select').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('MM/DD/YYYY'));
      });
    });
  }
  window.initiateDateRange();

  $('a.clear-filter').on('click', function(e){
    let $fld = $(e.target);
    apply_filter_to_datatable($fld.data('filter_by'), '');
  });

  $('a.datatable-clear-all').on('click', function (e) {
    const base_path = $(e.target).data('base-path');
    if(base_path) {
      let redirect_path = '';

      let regions = '';
      $('.page_filter_region').each(function(){
        let $fld = $(this);

        console.log('page_filter_region', $fld);

        if( $fld.is(':checked') && $fld.val() != 'all' ) {
          if(regions != '') { regions += '-'; }
          regions += $fld.val()
        }
      });

      if( regions == '' ) {
        regions = 'all';
      }

      switch (base_path) {
        case 'sites':
          let region_site = $('input[name="region"]:checked').val();
          let go_live = $('input[name="go_live"]:checked').val();
          redirect_path = (region_site == 'all' && go_live == 'all') ? `/${base_path}/` : `/${base_path}/${go_live}/${regions}`;
          break;
        case 'inventories':
          let region_inv = $('input[name="region"]:checked').val();
          redirect_path = region_inv == 'all' ? `/${base_path}/` : `/${base_path}/${regions}`;
          break;
        default:
          redirect_path = `/${base_path}/`;
      }

      window.location = redirect_path;
    } else {
      const clear_path = $(e.target).data('clear-path');
      if(clear_path) {
        window.location = clear_path;
      }
    }
  });
});

window.apply_filter_to_datatable = function (name, val) {
  let url = new URL(window.location);
  if (val) {
    url.searchParams.set(`filter_by_${name}`, val);
  } else {
    url.searchParams.delete(`filter_by_${name}`);
    url.searchParams.delete(`missing_${name}`);
  }
  url.searchParams.set('page', 1);
  window.location = url;
}
