import {Controller} from "@hotwired/stimulus"
import moment from "moment";

// Connects to data-controller="insights--opus"
export default class extends Controller {
  connect() {}

  selectEditionForDelete(e) {
    document.getElementById("enableDeleteButton").value = "";
    document.getElementById("deleteEditionId").value = e.target.closest('a').dataset.editionId;
  }

  enableDeleteButton(e) {
    document.getElementById("deleteReportButton").disabled = e.target.value.toLowerCase() !== "deletereport";
  }

  deleteReport(e) {
    const editionId = document.getElementById("deleteEditionId").value;
    if (editionId) {
      fetch(`/insights/opus/${editionId}`, {method: 'DELETE'})
        .then((response) => response.text())
        .then(data => {
          document.querySelector(`#edition_row_${editionId}`)?.remove();
          const modal = bootstrap.Modal.getInstance('#delete-confirmation');
          modal.hide();
        });
    } else {
      alert("No report selected");
    }
  }

  selectRow(e) {
    if (e.target.closest('a')?.classList.contains('no-row-select')) {
      return;
    }

    let id = e.target.closest('tr')?.dataset.id;
    let url = new URL(window.location.href);
    let search_params = url.searchParams;

    // Unselect if selected.
    if (e.target.closest('tr')?.classList.contains('selected')) {
      search_params.delete('row_id');
    } else {
      // Select row.
      e.target.closest('table')?.querySelector('tr.selected')?.classList.remove('selected');
      e.target.closest('tr')?.classList.add('selected');
      search_params.set('row_id', id);
    }

    url.search = search_params.toString();
    let new_url = url.toString();
    window.location.href = new_url;

  }

  validate(e) {
    const dateValue = document.querySelector('#insights_opus_end_report_date')?.value;
    const selectedDate = moment(dateValue);
    document.querySelector('button#generateReport')?.classList.add('disabled');
    if (selectedDate.isValid()) {
      fetch(`/insights/opus/validate?selected_date=${dateValue}`)
        .then(response => response.text())
        .then(
          html => Turbo.renderStreamMessage(html)
        );
    }
  }
}
