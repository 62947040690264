import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sites"
export default class extends Controller {
  list(event) {
    let $caret_icon = $(event.target).find('.icon');
    const { allocationId } = event.target.dataset;

    // Clear existing shipment contents
    Array.from(document.querySelectorAll(`.child-table-placeholder`)).forEach((tr) => {
      tr.style.display = 'none';
    });

    if (event.target.dataset.expanded === 'true') {
      // Skip
      event.target.dataset.expanded = 'false';
      $caret_icon.addClass('icon-caret-right').removeClass('icon-caret-down');
    } else {
      const shipmentPlaceholder = document.querySelector(`#shipment-placeholder-${allocationId}`);
      shipmentPlaceholder.style.display = 'table-row';
      this.shipmentsData(event.target, allocationId);
      event.target.dataset.expanded = 'true';
      $caret_icon.removeClass('icon-caret-right').addClass('icon-caret-down');
    }
  }

  shipmentsData(target, allocationId) {
    $.get($(target).data('url'));
  }
}
