import { Application } from "@hotwired/stimulus"

const application = Application.start()
window.Stimulus   = application
export { application }


// Go into Debug Mode if the environment is not production
let is_debug_set = false;

document.addEventListener("DOMContentLoaded", () => {
  if (!is_debug_set) {
    application.debug = document.body.getAttribute('data-environment') !== 'production';
    is_debug_set = true;
  }
});